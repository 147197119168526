body {
  margin: 0;
  background-color: #F2F2F2;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-danger {
  /* color: red;  */
  color: #f62d51 ; 
}

.tags-wrapper {
  flex-wrap: wrap;
}

.tags-wrapper .ant-tag {
  border: none;
  border-radius: 50px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  padding: 4px 12px;
  margin-right: 0;
}

.tags-wrapper .ant-tag svg {
  padding-left: 10px;
  cursor: pointer;
}

.tags-wrapper .ant-tag-success {
  background-color: #E5F8FF;
  color: #0079A8;
}

.tags-wrapper .ant-tag-default {
  background-color: #E7EBEF;
  color: #2B2829;
}

@media (max-width: 768px) {
  .table-container .ant-table {
    overflow-x: auto;
  }
}