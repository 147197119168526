.candidate-card {
    border-radius: 1px;
    border: 1px solid #DAE1E7;
}

.candidate-user-info h2 {
    display: inline-block;
    margin-inline: 10px;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.2px;
    color: #2B2829;
}

.candidate-user-info .ant-checkbox {
    padding-right: 8px;
}

.candidate-user-info .ant-checkbox-inner::after {
    inset-inline-start: 16%;
    top: 42%;
}

.candidate-user-bio {
    padding: 0px 0px 0px 60px;
    overflow: auto;
}

.candidate-card .ant-card-head {
    padding: 16px;
}

.user-bio-details {
    display: flex;
    /* gap: 20px; */
    font-size: 14px;
    font-weight: 600;
    flex-wrap: wrap;
    padding-bottom: 10px;
}

.user-bio-list {
    display: flex;
    align-items: center;
    gap: 6px;
    padding-right: 20px;
}

.user-bio-list span {
    color: #5A5555;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.8px;
    text-align: left;
}

.user-bio-info {
    font-size: 14px;
    display: flex;
    padding: 0px 60px;
    flex-wrap: wrap; 
}

.user-bio-labels {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.user-bio-labels .bio-item {
    flex: 1 1 48%; 
    box-sizing: border-box; 
    min-width: 300px;
    flex-wrap: wrap;
}

.bio-item {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
}

.user-bio-labels {
    color: #726c6c;
    flex: 1; 
    overflow-wrap: break-word;
}

.user-bio-values {
    color: black;
}

.candidate-button-group {
    padding: 12px 16px;
}

.candidate-button-group .ant-btn {
    border-color: #00ADF0;
    padding: 8px 16px;
    display: flex;
    align-items: center;
}

.candidate-button-group .ant-btn span {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.8px;
    color: #00ADF0;
    display: inline-flex;
    align-items: center;
}

.candidate-user-info .ant-avatar {
    background-color: #F7F9FA;
    color: #4A5E6D;
    font-family: "Inter", sans-serif;
    font-size: 11.79px;
    font-weight: 500;
    line-height: 20.63px;
}

.candidate-card .card-footer {
    border-top: 1px solid #DAE1E7;
    padding: 8px 16px;
    flex-wrap: wrap;
}

.candidate-card .ant-card-body {
    padding: 0;
}

.candidate-card ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    align-items: center;
}

.candidate-card li {
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.4px;
    color: #726C6C;
    display: flex;
    align-items: center;
    gap: 3px;
}

.candidate-card li:first-child {
    border-right: 1px solid #B5B3B3;
    padding-right: 8px;
}

.candidate-card .ant-btn-link {
    padding: 0;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;
    color: #5A5555;
    margin-left: 5px;
    height: fit-content;
    display: flex;
    align-items: center;
    gap: 5px;
}

.mobile-menu-modal {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #fff;
    transform: translateY(100%);
    transition: transform 0.3s;
    z-index: 9999;
    width: 100%;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}

.mobile-menu-modal .candidate-left {
    /* height: 90vh; */
    height: auto;
    overflow-y: scroll;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;     
}

.mobile-menu-modal.active {
    transform: translateY(0);

}

.mobile-menu .close {
    background-color: #fff;
    border: none;
    width: 38px;
    height: 38px;
    border-radius: 100%;
    padding: 0;
    display: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -50px;
    right: 10px;
}

.mobile-menu-modal.active .close {
    display: flex;
}

.mobile-menu-modal .close svg {
    width: 13px;
    height: 13px;
}

.mobile-menu .close svg path {
    stroke: #433E3F;
}

@media (max-width:1199px) {
    .candidate-user-bio {
        padding-left: 30px;
    }
}

@media (max-width:767px) {
    .candidate-card .ant-card-head {
        padding: 6px 16px;
    }

    .candidate-user-bio {
        padding-left: 20px;
    }

    .candidate-card .ant-card-head {
        padding: 6px 16px;
    }

    .user-bio-info {
        padding: 0px 20px;
        flex-direction: column;
    }

    .user-bio-labels .bio-item {
        flex: 1 1 100%;  
    }

    .candidate-card ul {
        flex-wrap: wrap !important;
        margin-bottom: 5px;
    }

    .mobile-menu-modal .candidate-user-info {
        padding: 12px 16px 12px;
    }
}