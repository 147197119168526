.dashboard-layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}

.main-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.sidebar {
  position: fixed;
  top: 60px;
  bottom: 0;
  width: 250px;
  /* overflow-y: auto; */
  z-index: 1000;
}

.main-content {
  margin-top: 48px;
  margin-left: 240px;
  /* padding: 20px; */
  padding-left: 20px;
  overflow-y: auto;
  flex-grow: 1;
  height: calc(100vh - 60px);
}
@media (max-width:767px) {
  .main-content {
    padding: 47px 0 0 0;
    margin: 0;
  }

  .sidebar {
    transform: translateX(-100%);
    transition: transform 0.3s;
  }

  .sidebar.active {
    transform: translateX(0);
    margin-top: -12px;
  }
}