.main-header {
  height: 48px;
  /* width: 98%; */
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #dae1e7;
  box-sizing: border-box;
  background-color: #ffff;
}

.header-left {
  display: flex;
  align-items: center;
}

.header-left .menu-icon {
  margin-right: 10px;
  cursor: pointer;
  width: 34px;
  height: 34px;
  padding: 8.5px 5.67px 8.5px 5.67px;
  gap: 0px;
  opacity: 1px;

}

.header-left .logo {
  height: 32px;
  margin-left: 10px;
  width: 150px;
  height: 40px;
  gap: 0px;
  opacity: 1px;

}

.header-right {
  width: 32px;
  height: 32px;
  background: #d8f4ff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #00adf0;
  cursor: pointer;
}

@media (max-width: 767px) {

  .header-left {
    display: flex; 
    align-items: center;
    gap: 75px
  }

  .main-header {
    background-color: white;
  }

  .header-left .menu-icon {
    margin-right: 0;
  }

  .header-left .logo {
    margin-left: 0;
  }
}

@media (max-width: 480px) {
  .main-header {
    padding: 10px 5px;
  }
}