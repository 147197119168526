:where(.css-dev-only-do-not-override-1uq9j6g).ant-tabs > .ant-tabs-nav,
:where(.css-dev-only-do-not-override-1uq9j6g).ant-tabs > div > .ant-tabs-nav {
  margin-bottom: 0 !important;
}

.rcontainer {
  padding: 20px;
  background-color: #F2F2F2;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.title {
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
}

.subtitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
}

.primary-button {
  background-color: #1890ff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.primary-button:hover {
  background-color: #40a9ff;
}

.tab-content {
  background-color: #ffffff;
  padding: 20px;
  margin-top: 10px;
}

.section-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  margin-bottom: 10px;
}

.section-subtitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  color: #726c6c;
  margin-bottom: 20px;
}

.learn-more {
  color: #1890ff;
  cursor: pointer;
}

.credits-container {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

.credit-card {
  width: 100%;
  /* max-width: 347px; */
  /* height: 155px; */
  height: 120px;
  border: 1px solid #dae1e7;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.credit-card-header {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  border-bottom: 1px solid #dae1e7;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.credit-card-icon {
  font-size: 20px;
  margin-right: 10px;
}

.credit-card-body {
  font-size: 14px;
  padding: 8px;
}

.credits-number {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 5px;
}

.credits-number span {
  font-size: 16px;
  font-weight: 500;
  color: #726c6c;
}

.credits-expiry {
  font-size: 12px;
  color: #726c6c;
  margin-bottom: 10px;
}

.view-all {
  color: #1890ff;
  cursor: pointer;
}

.usage-limits-container {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  margin-top: 20px;
}

.usage-limit-card {
  width: 100%;
  /* max-width: 347px; */
  height: 120px;
  border: 1px solid #dae1e7;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}

.usage-limit-header {
  font-size: 14px;
  font-weight: 600;
  border-bottom: 1px solid #dae1e7;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.usage-limit-body {
  font-size: 14px;
  padding: 8px;
}

.usage-number {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 5px;
}

.usage-number span {
  font-size: 16px;
  font-weight: 500;
  color: #726c6c;
}

.usage-info {
  font-size: 12px;
  color: #726c6c;
}

.divStyle {
  border: 1px solid #c2cdd6;
  border-radius: 20px;
  padding: 8px 16px;
  cursor: pointer;
  margin-right: 10px;
}

.selectedDivStyle {
  background-color: #e5f8ff;
  color: #00adf0;
  border: 1px solid #00adf0;
}

.credit-filter-group {
  display: flex !important;
  justify-content: space-between;
  flex: 3;
  height: auto;
  gap: 10px;
  flex-wrap: wrap;
}

.date-label{
  font-weight: 500;
  font-size: 14px;
  padding-right: 5px;
}

.credit-date-input {
  width:120px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #CED7DE;
  padding: 10px 14px;
}

@media (max-width: 768px) {
  .header {
    align-items: center;
  }

  .primary-button {
    margin-top: 20px;
  }

  .credits-container,
  .usage-limits-container {
    flex-direction: column;
  }

  .credit-card,
  .usage-limit-card {
    width: auto;
    margin-bottom: 20px;
  }

  .tab-content {
    padding: 10px;
  }
}
