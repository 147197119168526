/* styles/Dashboard.css */
.dashboard-container {
    max-width: 100%;
    padding: 20px;
  }
  
  .header {
    margin-bottom: 20px;
  }
  
  .header-title {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .header-subtitle {
    color: #666;
    margin-top: 5px;
  }
  
  .stats-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: space-between;
  }
  
  .stats-card {
    background-color: #ffffff;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 15px;
    width: calc(25% - 15px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .stats-card img {
    margin-right: 15px;
    width: 50px;
    height: 50px;
  }
  
  .stats-right-content {
    flex-grow: 1;
  }
  
  .stats-right-content .title {
    color: #666;
    font-size: 0.9rem;
    margin-bottom: 5px;
  }
  
  .stats-right-content .value {
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  .chart-container {
    /* height: 270px; */
    background-color: #ffffff;
    margin-top: 20px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .chart-title {
    text-align: center;
    margin-bottom: 20px;
    font-weight: bold;
  }
  
  .flex-row-container {
    display: flex;
    gap: 20px;
  }

  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  @media (max-width: 768px) {
    .stats-cards {
      flex-direction: column;
    }
  
    .stats-card {
      width: 100%;
    }
  
    .flex-row-container {
      flex-direction: column;
    }
  
    .flex-row-container > div {
      width: 90% !important;
    }
  }