.search-candidate {
    background: #F2F2F2;
    /* height: 100%; */
    padding: 32px
}

.header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.header-title {
    font-size: 22px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;
    margin-top: 0;
}

.search-bg-wrapper {
    background-color: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #CED7DE;
}



.search-bg-wrapper .space {
    padding: 24px 32px;
}

.search-bg-wrapper .space:not(:last-child) {
    border-bottom: 1px solid #CED7DE;
}

.search-candidate .ant-radio-group {
    display: flex;
    gap: 16px;flex-wrap: wrap;
}

.search-candidate .ant-radio-wrapper-checked {
    background-color: #E5F8FF !important;
}

.search-candidate .ant-radio-wrapper {
    background: transparent;
    border: 1px solid #CED7DE;
    border-radius: 5px;
    padding: 12px 24px;
    position: relative;
    flex-direction: column;
    min-width: 102px;
    text-align: center;
    gap: 14px;
    margin: 0;
}

.search-candidate .ant-radio-wrapper::after {
    display: none;
}

.search-candidate .ant-radio-wrapper p {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    line-height: 16.8px;
    display: flex;
    align-items: center;
    gap: 5px;
}

.search-candidate .ant-radio-checked .ant-radio-inner {
    border: 2px solid #00ADF0;
    background-color: #00ADF0;
}


.search-bg-wrapper h3,
.form-group label {
    margin-bottom: 12px;
    display: block;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.8px;
    color: #2B2829;margin-top: 0;
}

.form-group:not(:last-child) {
    margin-bottom: 15px;
}

.form-group input {
    border-radius: 4px;
    border: 1px solid #CED7DE;
    padding: 11px 14px;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
}

.form-group input::placeholder {
    color: #7991A4;
}

.form-group label .required {
    color: #B42318;
}

.form-group .ant-select {
    height: 40px;
    width: 100%;
}

.form-group .ant-select .ant-select-selector {
    color: #7991A4;
    border-color: #CED7DE;
}

.form-group .ant-btn-link,
.form-group .ant-select .ant-select-selector {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: left;border-radius: 4px;
}
.form-group .ant-select .ant-select-selector{border-color:#CED7DE}
.form-group .ant-select .ant-select-selector::placeholder{color: #7991A4;}

.form-group .ant-btn-link {
    justify-content: flex-start;
    color: #00ADF0;
    padding: 0;
    margin-top: 10px;
}

.search-bg-footer {
    box-shadow: 0px 7px 29px 0px #64646F33;
    padding: 24px 32px;
}

.search-bg-footer label {
    flex: none;
}

.search-bg-footer .ant-btn-text {
    background-color: transparent;
    color: #00ADF0;padding: 0;
}

.search-bg-footer .ant-btn {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.2px;
}

.search-bg-footer .ant-btn-primary {
    background-color: #00ADF0;
    color: #fafafa;
}

.search-bg-footer .ant-btn-primary img {
    filter: brightness(0) invert(1);
}

.search-candidate-right .candidate-filter {
    padding: 8px 16px;
}

.search-candidate-right .candidate-filter h3 {
    margin-bottom: 0;display: inline-flex;
}

.search-candidate-right .candidate-filter h3 img {
    width: 24px;
    height: 24px;
}

.search-candidate-right .candidate-filter .ant-btn-link {
    width: fit-content;
    color: #00ADF0;
    padding: 0;
}

.search-candidate-right ul {
    list-style: none;
    padding: 16px;
    margin: 0;
}

.search-candidate-right ul:not(:last-child) {
    border-bottom: 1px solid #DAE1E7;
}

.search-candidate-right ul li div {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    gap: 12px;
}

.search-candidate-right ul li {
    font-family: "Inter", sans-serif;
    color: #5A5555;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
}

.search-candidate-right ul li:not(:last-child) {
    margin-bottom: 8px;
}

.search-candidate-right ul li button {
    font-family: "Inter", sans-serif;
    width: fit-content !important;
    padding: 0 !important;
    height: fit-content;
    color: #00ADF0;
    font-weight: 500;
}
/* .header{display: block;} */
    .header.mobile-show-header{display: none;margin-top: 10px;}
@media (max-width:1024px){
    .search-candidate{padding: 25px;}
    .search-bg-wrapper .space{padding: 20px 28px;}
}
@media (max-width:991px){
    .search-candidate-row{flex-direction: column-reverse !important;}
    .search-candidate{padding: 20px;}
    .search-bg-wrapper .space{padding: 15px 20px;}

}
@media (max-width:767px) {
    /* .header{display: none;} */
    .header.mobile-show-header{display: block}
    .search-candidate{padding: 16px;}
    .search-candidate .ant-radio-wrapper{width: 100%;}
    .search-candidate .ant-radio-wrapper span{
        margin: 0 auto;
    }
    .search-candidate-right .candidate-filter h3{
        font-size: 20px;font-weight: 600;
    }
    .search-bg-footer{padding: 12px 16px;}
    .search-bg-footer .ant-btn-text{display: none;}
    .search-bg-wrapper.mobile-search-wrapper{background-color: transparent;border: none;}
    .search-candidate-right .candidate-filter{margin-bottom: 10px;background-color: white;}
    .search-candidate-right .candidate-recent-search{overflow-x: auto;
        display: flex; gap: 8px;
    }
    .search-candidate-right ul{background-color: white;}
    .search-bg-wrapper .space{padding: 16px;}
    .form-group:not(:last-child){margin-bottom: 15px;}
    .search-candidate{padding-bottom: 100px;}
    .search-bg-footer{position: fixed;bottom: 0;left: 0;width: 100%;background-color: #fff;padding: 0;}
    .candidate-right-head{padding: 12px 16px;}
    .candidate-right-head .ant-select{width: 100px !important;}
    .candidate-right-head .active-in{flex-direction: column;align-items: start;gap: 6px !important;}
    .candidate-right-head .active-in{font-weight: 500;}
}