.invalid-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    /* padding: 20px; */
    background-color: #f0f0f0;
  }
  
  .error-icon {
    width: 150px;
    height: 150px;
    margin-bottom: 20px;
  }
  
  .invalid-page-title {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  .invalid-page-message {
    font-size: 1.25rem;
    color: #666;
    margin-bottom: 30px;
  }
  
  .go-back-button {
    padding: 10px 20px;
    font-size: 1rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .go-back-button:hover {
    background-color: #0056b3;
  }
  
  
  @media (max-width: 600px) {
    .invalid-page-title {
      font-size: 2rem;
    }
  
    .invalid-page-message {
      font-size: 1rem;
    }
  
    .error-icon {
      width: 120px;
      height: 120px;
    }
  }
  