  @media (max-width: 768px) {
    .input-group {
      margin: 10px 0;
    }
  
    .input-with-icon {
      flex-direction: column;
      align-items: stretch;
    }
  
    input[type="text"] {
      font-size: 14px;
      padding: 8px;
    }
  
    .button {
      padding: 10px;
      font-size: 14px;
    }
  
    .resend-otp {
      font-size: 14px;
    }
  }
  

  @media (max-width: 480px) {
    .input-group {
      margin: 5px 0;
    }
  
    .input-with-icon {
      flex-direction: column;
      align-items: stretch;
    }
  
    input[type="text"] {
      font-size: 12px;
      padding: 6px;
    }
  
    .button {
      padding: 8px;
      font-size: 12px;
    }
  
    .resend-otp {
      font-size: 12px;
    }
  }