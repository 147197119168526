.profiles-head {
    padding: 10px 32px;
}

.profiles-head .title button {
    background-color: #E7EBEF;
    width: 36px;
    border: none;
    height: 36px;
    border-radius: 5px;
}

.profiles-head .title p {
    font-size: 14px;
    margin: 0;
    font-weight: 400;
    line-height: 16.8px;
    font-family: "Inter", sans-serif;
}

.profiles-head .title p span {
    margin-inline: 5px;
    font-weight: 500;
}

.profiles-head .candidate-button-group {
    padding: 0;
}

.candidate {
    /* height: 90vh; */
    background-color: #F2F2F2;
    padding: 20px 32px;
}

.candidate-left .ant-collapse-item {
    background-color: transparent;
}


.candidate-left .ant-collapse-header {
    padding: 16px;
    flex-direction: row-reverse;
}

.candidate .candidate-left .ant-collapse-header .ant-collapse-expand-icon {
    padding-inline-end: 0;
}

.candidate .candidate-left .ant-collapse-header .ant-collapse-expand-icon svg {
    transform: rotate(90deg)
}

.candidate .candidate-left .ant-collapse-header .ant-collapse-expand-icon span[aria-label="expanded"] svg {
    transform: rotate(270deg) !important;
}

.candidate .candidate-left {
    background-color: white;
    border: 1px solid #DAE1E7;
    border-radius: 4px;
}

.candidate-left .ant-checkbox-wrapper {
    width: 100%;
    margin-bottom: 12px;
}

.candidate .ant-radio-checked .ant-radio-inner,
.checkbox-list .ant-checkbox-checked .ant-checkbox-inner {
    border: 2px solid #00ADF0;
    background-color: #00ADF0;
}

.checkbox-list .ant-checkbox-inner::after {
    top: 45%;
    width: 7px;
    height: 12px;
}

.candidate-left .ant-btn-link {
    justify-content: flex-start;
    padding: 0 0 0 23px;
    width: fit-content !important;
}

.ant-select-dropdown {
    z-index: 9999;
}

#rc_select_1_list {
    overflow: visible;
}
  
.candidate-left .ant-btn-link span {
    justify-content: flex-start;
}

.candidate-left .ant-btn-link span {
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;
    color: #00ADF0;
    font-family: "Inter", sans-serif;
}

:is(.candidate, .checkbox-list) .ant-checkbox-inner {
    border: 2px solid #B6C3CD;
    border-radius: 2px;
    width: 20px;
    height: 20px;
}

.candidate .ant-collapse-header-text {
    font-size: 16px;
    font-weight: 600;
    font-family: "Inter", sans-serif;
    color: #2B2829;
}

.candidate-left .ant-collapse {
    background: #fff;
    border: none;
    border-radius: 0;
}

.candidate-filter {
    padding: 11px 16px;
    border-bottom: 1px solid #DAE1E7;
}

.candidate-filter h3 {
    margin: 0;
    display: inline-flex;
    gap: 10px;
    align-items: center;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.8px;
    color: #2B2829;
}

.candidate-filter h3 img {
    width: 20px;
    height: 18px;
}

.candidate-filter .ant-btn-link span {
    font-weight: 600;
}

:is(.candidate, .checkbox-list) :is(.ant-checkbox-wrapper, .ant-space-item) :is(span, small, label) {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    font-family: "Inter", sans-serif;
}

:is(.candidate, .checkbox-list) :is(.ant-checkbox-wrapper, .ant-space-item) span {
    color: #2B2829;
}

:is(.candidate, .checkbox-list) :is(.ant-checkbox-wrapper, .ant-space-item) small {
    color: #726C6C;
}

.candidate-left .chips-button button {
    background-color: #E5F8FF !important;
    flex-direction: row-reverse;
    border: none;
    display: flex;
    border-radius: 50px !important;
}

.candidate-left .experience .ant-radio-group,
.candidate-left .exp {
    margin-top: 24px;
    width: 100%;
    flex-wrap: nowrap !important;
    gap: 8px;
}

.candidate .download-btn span {
    font-weight: 500;
}

.candidate-left .experience .ant-radio-group {
    display: flex;
    gap: 16px;
    margin-top: 0;
}

.checkbox-list.experience .ant-radio-wrapper-checked {
    background-color: #E5F8FF !important;
}

.checkbox-list.experience .ant-radio-wrapper {
    background: transparent;
    border: 1px solid #CED7DE;
    border-radius: 5px;
    padding: 10px;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    position: relative;
    font-weight: 500;
    line-height: 16.8px;
    margin: 0;
    flex: auto;
}

.checkbox-list.experience .ant-select {
    height: 40px;
}

.checkbox-list.experience .ant-radio-wrapper img {
    position: absolute;
    right: 8px;
}

.candidate-left .exp .ant-space-item {
    width: 50%;
    margin: 0;
}

.candidate-left .exp .ant-space-item .ant-select-selector {
    border-color: #CED7DE;
}

.candidate-left .exp .ant-space-item .ant-select-selection-item {
    color: #7991A4;
}

.candidate-left .chips-button button span {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #0079A8;
}

.candidate-left .chips .ant-btn-link {
    padding-left: 10px;
    height: fit-content;
}

.candidate-left .ant-collapse-item {
    border-color: #DAE1E7;
}

.locations-modal .ant-modal-content {
    border-radius: 4px;
    box-shadow: 0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814;
outline: none;
}

.locations-modal .ant-modal-content .ant-modal-header {
    margin-bottom: 19px;
}

.locations-modal .ant-modal-content .ant-modal-header div {
    font-family: "Inter", sans-serif;
    color: #2B2829;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.8px;
}

.locations-modal .ant-modal-content .ant-input-outlined {
    border-radius: 5px;
    border-color: #CED7DE;
}

.locations-modal .ant-modal-content .ant-input-outlined input::placeholder {
opacity: 1;    color: #7991A4;
}

.locations-modal .checkbox-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 13px;
}

.locations-modal .ant-modal-title {
    color: #2B2829;
    font-size: 14px;
    font-weight: 500;
    font-family: "Inter", sans-serif;
    line-height: 16.8px;
    text-align: left;
}

.locations-modal .ant-btn-primary {
    background-color: #00ADF0;
    padding: 9px 20px;
    height: fit-content;
}

.locations-modal .ant-btn-default {
    display: none;
}

.locations-modal .ant-modal-footer {
    margin-top: 30px;
}

.candidate-right {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.candidate-right .candidate-right-head {
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 16px;
}

.candidate-right .ant-pagination {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}

.candidate-right .ant-pagination li:is(:first-child, .ant-pagination-next) {
    background-color: #E7EBEF;
    border-radius: 4px;
}

.candidate-right .ant-pagination li.ant-pagination-item-active {
    background-color: #E5F8FF;
    border-color: #E5F8FF;
    color: #00ADF0;
    font-weight: 600;
}

.candidate-right .ant-pagination li:last-child {
    order: -1;
    /* margin-left: 0; */
    margin-right: auto;
}

.candidate-right-head .ant-space-item .ant-flex {
    gap: 10px;
    align-items: center;
}

.candidate-right-head .ant-space-item label {
    flex: none;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;
    color: #2B2829
}

.candidate-right-head .ant-select {
    height: 40px;
    border-radius: 5px;
}
.candidate-right .ant-checkbox-checked .ant-checkbox-inner{background-color: #4096ff;border-color: #4096ff;}
.candidate-right-head .ant-btn,
.candidate-right-head .ant-select .ant-select-selector {
    border-radius: 4px;
}

.candidate-right-head .ant-btn {
    background-color: #DAE1E7;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;
    color: #2B2829;
    padding: 12px 20px;
    display: flex;
    align-items: center;
}

.candidate-head {
    padding: 6px 32px;
    background-color: #ffff;
}

.candidate-head p {
    font-family: "Inter", sans-serif;
    color: #2B2829;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;
    margin: 0;
}

.candidate-head p b {
    padding: 0 5px;
}

.candidate-head .ant-btn-icon-only {
    background-color: #E7EBEF;
    border-color: #E7EBEF !important;
    border-radius: 5px;
}

.candidate-head .ant-flex {
    gap: 24px;
}

.candidate-head .ant-btn-default {
    border-color: #00ADF0;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    width: 36px;
    height: 36px;
}

.candidate-head .modify-btn {
    width: fit-content;
    height: fit-content;border-radius: 5px;
}

.candidate-head .ant-btn-default span {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.8px;
    color: #00ADF0;
    display: inline-flex;
    align-items: center;
}

.candidate-right-head .mobile-show {
    display: none;
}

.filter-btn {
    display: none;
}

@media (max-width: 991px) {
    .candidate {
        padding: 15px;
    }
    .candidate-head {
        padding: 6px 12px;
    }.candidate-head .ant-flex {
        gap: 10px;
    }
}

@media (max-width: 767px) {
    .candidate-user-mobile {
        display: none;
    }

    .locations-modal .checkbox-list {
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
    }

    .candidate-right-head {
        padding: 6px 16px !important;
    }

    .candidate-right-head .mobile-active-in {
        display: none;
    }

    .candidate-right-head .mobile-show {
        display: block;
    }

    .candidate-user-mobile {
        display: block;
    }

    .candidate .ant-pagination li {
        min-width: 28px;
        height: 28px;
        margin-inline-end: 5px;
    }

    .candidate-mobile {
        display: none !important;
    }

    .filter-btn {
        background-color: #F0FBFF;
        border: none;
        width: 38px;
        height: 38px;
        border-radius: 100%;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        bottom: 10px;
        right: 20px;
        z-index: 1;
        box-shadow: 0px 2.63px 5.25px -2.63px #1018280F;
        box-shadow: 0px 5.25px 10.5px -2.63px #1018281A;
    }
}

@media (max-width: 576px) {

    .locations-modal .checkbox-list {
        grid-template-columns: 1fr;
    }.candidate-head .modify-btn {
        padding: 8px;
        width: 120px;
    }

    .candidate-head p,
    .candidate-head .modify-btn span {

        font-size: 12px;
    }
}