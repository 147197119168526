.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-top: 30px;
}

.label {
  color: #5a5555;
  font-size: 16px;
  font-weight: 600;
  line-height: 16.8px;
  text-align: left;
}

.value {
  color: black;
  font-size: 16px;
  font-weight: 500;
  line-height: 16.8px;
  text-align: left;
  padding-top: 5px;
}

.profile-card {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
}

.profile-card-heading {
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
}

.candidate-main-profile {
  width: 40vw;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: 1fr;
  }

  .candidate-main-profile {
    flex-direction: column;
  }
}
