.button-group {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .button-group button {
    padding: 0 24px;
    gap: 8px;
    border-radius: 4px;
    font-weight: 600; 
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .button-group .primary-button {
    width: 183px;
    height: 40px;
    background-color: #00ADF0;
    color: #FAFAFA;
    border: 1px solid #00ADF0;
    cursor: pointer;
  }
  
  .button-group .secondary-button {
    width: auto;
    height: 40px;
    color: #00ADF0;
    border: 1px solid #00ADF0;
    cursor: pointer;
  }
  
  .candidate-create-raw {
    display: flex;
    gap: 16px;
  }
  
  .candidate-create-input-div {
    width: 100%;
  }
  
  .candidate-create-input-div label {
    font-size: 14px;
    font-weight: 500;
    color: #323F49;
    line-height: 17px;
  }
  
  .candidate-create-input {
    border-radius: 4px;
    border: 1px solid #CED7DE;
    padding: 10px 14px;
    width: 100%;
  }

  .candidate-select-input {
    height: 45px;
    width: 100%;
  }
  
  .ant-picker-input {
    width: 100%;
  }

  .logo-content {
    margin-bottom: 5px;
    display: flex;
    gap: 10px;
  }

  .cv-upload-content {
    border: 2px dashed #00adf0;
    padding: 30px 10px;
    border-radius: 20px;
    width: 72vw;
    height: 80px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


  @media (max-width: 768px) {
    
    .chakkra-logo {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .logo-content {
     flex-direction: column
    }

    
  }