/* Sidebar container */
.sidebar {
  background-color: #ffffff;
  border-right: 1px solid #e0e6ed;
  padding: 5px;
  margin-top: -11px;
  /* overflow-y: auto;  */
}

/* Inner container */
.sidebar-inner {
  width: 100%;
  height: 100%;
}

/* Sidebar header styling */
.sidebar-header {
  display: flex;
  align-items: center;
  /* padding: 15px 20px; */
  flex-direction: column;
}

/* .logo {
  margin-right: 10px;
} */

.company-name {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

/* Divider line */
.divider {
  border: 1px solid #dae1e7;
}

/* Sidebar menu styling */
#sidebar-menu ul {
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 0;
}

#sidebar-menu li {
  list-style-type: none;
  /* gap: 20px; */
}

.sidebar-link {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  color: #333;
  text-decoration: none;
  gap: 20px;
  padding-left: 30px;
  border-radius: 10px;
}

.sidebar-link:hover {
  background-color: #f2f4f8;
}

/* Active link */
.sidebar-link.active {
  background-color: #e6f2ff;
  font-weight: 500;
  /* color: #007bff; */
}
