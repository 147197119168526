  :root {
    --spacing-6: 14px;
    --spacing-3: 8px;
    --border-radius-medium: 8px;
    --button-large-height: 50px;
    --primary-primary: #00ADF0;
    --Primary-Primary200: #B2E9FF;
  }
  
  .background-image {
    height: 100vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .container {
    display: flex;
    justify-content: center;
    box-shadow: 0px 4px 6px -2px #10182808;
    background-color: #ffffff;
    width: 816px;
    height: 510px;
    border: 1px solid #dae1e7;
    box-sizing: border-box;
  }
  
  .left-panel,
  .right-panel {
    padding: 14px;
  }
  
  .left-panel {
    width: 60%;
    padding: 35px;
  }
  
  .right-panel {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .logo {
    width: 180px;
    height: 60px;
  }

  .powered-by {
    font-size: 12px;
    color: #7d7d7d;
    /* margin-top: 10px; */
    padding-left: 11px;
    font-weight: 500;
  }
  
  .heading {
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    margin-top: 35px;
  }
  
  .input-group {
    position: relative;
    margin-top: 28px;
  }

  .input-with-icon {
    position: relative;
  }

  .input-with-icon .fa {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #ced7de;
    pointer-events: none;
    opacity: 1;
  }

  .input-with-icon .fa.fa-envelope-o {
    left: 10px;
  }

  .input-with-icon .fa.fa-phone {
    left: 10px;
  }

  .input-with-icon .fa.fa-lock {
    left: 10px;
  }
  
  .input-with-icon .fa.fa-eye,
  .input-with-icon .fa.fa-eye-slash {
    right: 10px;
    pointer-events: auto;
  }
  
  .input-group label {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 8px;
    display: block;
  }
  
  .input {
    width: 100%;
    height: 40px;
    padding: 10px 14px;
    padding-left: 30px;
    gap: 8px;
    border-radius: 4px;
    border: 1px solid #ced7de;
    opacity: 1;
    box-sizing: border-box;
  }
  
  .button {
    width: 100%;
    height: var(--button-large-height);
    padding: 0 var(--spacing-6);
    gap: var(--spacing-3);
    border-radius: var(--border-radius-medium);
    opacity: 1;
    background: var(--primary-primary, #00ADF0);
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }

  .button:disabled {
    background: #75c7e7; 
    cursor: not-allowed;
    opacity: 0.6;
  }
  
  .contact-admin {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    text-align: left;
  }

  .forgot-password {
    margin-top: 15px;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    color: var(--primary-primary, #00ADF0);
    cursor: pointer;
  }
  
  .contact-admin span {
    color: var(--primary-primary, #00ADF0);
    cursor: pointer;
  }
  
  .right-panel img {
    max-width: 100%;
    height: auto;
  }
  
  .slide-heading {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    margin-top: 30px;
  }
  
  .slide-description {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    margin-top: 8px;
  }

  .loading-circle{
    display: flex;
    margin-top: 20px;
    gap: 5px;
  }

  .active-circle {
    width: 6px;
    height: 6px;
    background: var(--primary-primary, #00ADF0);
    border-radius: 50%;
  }

  .circle {
    width: 6px;
    height: 6px;
    background: var(--Primary-Primary200, #B2E9FF);
    border-radius: 50%;
  }
  
  @media (max-width: 1024px) {
    .background-image {
        padding: 20px;
      }
    
      .right-panel {
        display: none;
      }
  }
  
  @media (max-width: 768px) {
    .background-image {
      padding: 20px;
    }
  
    .right-panel {
      display: none;
      border: none;
    }
  }
  