.candidate-details-modal {
  background-color: #fff;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
  max-width: 700px;
  width: 100%;
  height: 100%;
}
.candidate-details-overlay {
  background-color: #1e2329d6;
  inset: 0;
  position: fixed;
  z-index: 1000;
}
.candidate-details-modal .modal-header {
  padding: 24px 16px 24px;
}
.candidate-details-modal .modal-header .ant-flex {
  margin-bottom: 12px;
}
.candidate-details-modal .modal-header .ant-avatar {
  background-color: #f7f9fa;
  color: #4a5e6d;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 28px;
}

.candidate-details-modal .modal-header .candidate-user-info {
  display: flex;
  align-items: center;
  gap: 10px;
}
.candidate-details-modal .modal-header h2 {
  margin: 0;
}
.candidate-details-modal .modal-header .candidate-user-bio {
  padding: 0;
  margin-top: 12px;
}
.candidate-details-modal .modal-header .candidate-user-bio .ant-flex {
  gap: 16px;
}
.candidate-details-modal .ant-tabs-nav {
  margin-bottom: 0;
}
.candidate-details-modal .ant-tabs-nav-list {
  padding-inline: 24px;
  gap: 32px;
}
.candidate-details-modal .ant-tabs-nav-list .ant-tabs-tab {
  margin: 0;
}
.candidate-details-modal .ant-tabs-nav-list .ant-tabs-tab-btn {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: "Inter", sans-serif;
}
.candidate-details-modal .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #00adf0 !important;
  font-weight: 600 !important;
}

.candidate-details-modal .ant-tabs-content {
  overflow-y: auto;
  height: calc(100vh - 157px);
}
.candidate-details-modal .ant-tabs-content ul {
  padding: 24px;
}
.candidate-details-modal textarea {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  padding: 10px 14px;
}
.candidate-details-modal textarea::placeholder {
  color: #637d92;
}
.candidate-details-modal ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.candidate-details-modal ul li:not(:last-child) {
  margin-bottom: 24px;
}
.candidate-details-modal ul .user-bio-list {
  margin-bottom: 16px;
}
.candidate-details-modal ul .user-bio-list span {
  color: #2b2829;
}

.candidate-details-modal ul li .dots {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  padding-left: 20px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
  color: #2b2829;
  position: relative;
}
.candidate-details-modal ul li .dots::before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
  height: 8px;
  background: #ced7de;
  border-radius: 50%;
}

.candidate-details-modal .tags-wrapper .ant-tag {
  background-color: #f1f4f6;
  padding: 8px;
  font-size: 12px;
  line-height: 14.4px;
}

.candidate-details-modal .resume-box .ant-flex {
  margin-bottom: 16px;
}
.candidate-details-modal .resume-box .user-bio-list {
  margin-bottom: 0;
}
.candidate-details-modal .resume-box .ant-btn-link {
  width: fit-content;
  padding: 0;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.8px;
  color: #00adf0;
}

.candidate-details-modal .ant-avatar {
  font-family: "Inter", sans-serif;
  background-color: #d8f4ff;
  color: #00adf0;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  flex: none;
  text-transform: uppercase;
}
.candidate-details-modal .comment-input {
  text-align: right;
}
.candidate-details-modal .comment-input :is(.ant-btn) {
  width: fit-content;
  padding: 10px 20px;
  height: fit-content;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
  text-align: center;
}
.candidate-details-modal .comment-input .ant-btn-default {
  background-color: #dae1e7;
  color: #2b2829;
}
.candidate-details-modal .comment-input .ant-btn-primary {
  background-color: #00adf0;
  color: #fff;
}
.candidate-details-modal .comment-items .comment-item {
  margin-bottom: 20px;
  position: relative;
}
.candidate-details-modal .comment-info {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
  color: #2b2829;
}
.candidate-details-modal .comment-info p {
  margin: 0;
  padding-bottom: 8px;
  color: #2b2829;
  font-weight: 500;
}
.candidate-details-modal .comment-info p span {
  font-weight: 400;
  color: #726c6c;
}
.candidate-details-modal .comment-info span {
  line-height: 1.5;
}
.candidate-details-modal .edit-delete-btn {
  position: absolute;
  right: 0px;
  bottom: 0;
}
.candidate-details-modal .edit-delete-btn button {
  width: fit-content;
  padding: 0;
  height: fit-content;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
  color: #323f49;
}
.candidate-details-modal .edit-delete-btn button:hover {
  background-color: transparent !important;
}
.edit-btn {
  color: #00adf0 !important;
}
.candidate-details-modal :is(.modal-unlocks, .candidate-button-group) {
  border-top: 1px solid #dae1e7;
  padding: 6px 24px;
}
.candidate-details-modal .candidate-button-group {
  padding: 12px 24px;
}
.candidate-details-modal .modal-unlocks p {
  color: #726c6c;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.4px;
  margin: 0;
}
.candidate-details-modal .modal-footer .ant-btn-text {
  border: none;
  padding: 0;
  gap: 3px;
}
.candidate-details-modal .modal-footer .ant-btn-text span {
  font-weight: 500;
  font-size: 14px;
}
.candidate-details-modal .modal-footer .previous span {
  color: #323f49;
}
.candidate-details-modal .modal-footer .next {
  flex-direction: row-reverse;
}
.candidate-details-modal .modal-footer .next span {
  color: #00adf0;
}
.candidate-details-modal .modal-footer .ant-btn-text .ant-btn-icon {
  display: flex;
  align-items: center;
}
.candidate-details-modal .modal-footer .ant-btn-text .anticon {
  font-size: 12px;
}
.candidate-details-modal .personal-details {
  padding: 0 !important;
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
}
.candidate-details-modal .personal-details li {
  margin-bottom: 0 !important;
}
.candidate-details-modal .personal-details h6 {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.4px;
  color: #726c6c;
  margin: 0;
}
.candidate-details-modal .personal-details span {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.4px;
  color: #2b2829;
}

.candidate-edit-button {
  color: #00adf0;
  background-color: #ffffff;
  border: 1px solid #00adf0;
  border-radius: 4px;
  width: 55px;
  height: 28px;
  cursor: pointer;
}

.candidate-copylink-button {
  color: #00adf0;
  background-color: #ffffff;
  border: 1px solid #00adf0;
  border-radius: 4px;
  width: auto;
  height: 28px;
  cursor: pointer;
}

.candidate-blacklist-button {
  color: #d92d20;
  background-color: #ffffff;
  border: 1px solid #d92d20;
  border-radius: 4px;
  width: auto;
  height: 28px;
  cursor: pointer;
}

.experience-grid-header {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 1fr;
  gap: 10px;
  font-weight: bold;
  padding: 10px 0;
  /* border-bottom: 1px solid #ccc; */
}

.experience-grid {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 1fr;
  gap: 10px;
  padding: 10px 0;
}

.experience-grid div {
  text-align: left;
  font-weight: 400;
}

.tags-wrapper1 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

@media (max-width: 767px) {
  .candidate-details-modal .comment-input {
    margin-bottom: 10px;
  }
  .candidate-button-group .ant-btn {
    flex: auto;
  }
  .candidate-details-modal .modal-footer .ant-btn {
    flex: none;
  }
  .candidate-details-modal .ant-tabs-content {
    overflow-y: auto;
    height: calc(100vh - 190px);
  }
  .candidate-details-modal .modal-header {
    padding: 16px 16px 10px;
  }
  .candidate-details-modal .edit-delete-btn {
    position: relative;
    margin-top: 10px;
  }
  .tags-wrapper1 {
    grid-template-columns: 1fr;
  }
}
