.modal-title {
    text-align: center;
    font-size: 25px;
    font-weight: 600
}

.upload-content {
    border: 2px dashed #00adf0;
    padding: 30px 10px;
    border-radius: 20px;
    width: 730px;
    margin-top: 20px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.import-button {
    width: 86px;
    height: 35px; 
    padding: 0 24px;
    gap: 8px;
    border-radius: 4px;
    opacity: 1;
    background-color: #00ADF0;
    color: #fff;
    border: none;
}

.cancel-button {
    width: 86px;
    height: 35px; 
    padding: 0 24px;
    gap: 8px;
    border-radius: 4px;
    opacity: 1;
    background-color: #DAE1E7;
    color: #2B2829;
    border: none;
}

.download-sample-sheet-button {
    margin-top: 10px;
    color: #00adf0;
    font-size: 15px;
    text-align: center;
    padding-top: 10px;
    cursor: pointer;
}


@media (max-width:767px) {
    .upload-content {
        width: 78vw;
    }
}